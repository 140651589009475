let host = window.location.host.split(':')[0];
// let api_endpoint = "http://" + host + ":8000/api/v1/"
// get demo from host (customer.demo.zebrain.se) with regex
let api_endpoint = ""
let home_page = ""
let system = "";
let app_url_start = "";

// Try get the github.dev from host glowing-space-winner-g4gg49gx44r2w59v-8100.app.github.dev
let codespace = host.match(/(.*)-8100\.app\.github\.dev/)

// check if we are on github.dev
if (codespace !== null) {
    system = "develop"
    app_url_start = "http://" + host + "/"
    // Get REACT_APP_CODESPACE_API
    api_endpoint = process.env.REACT_APP_CODESPACE_API + "/api/v1/"
} else if (host === 'localhost') {
    host = 'localhost'
    system = "develop"
    api_endpoint = "http://" + host + ":8000/api/v1/"
    home_page = `https://www.zebrain.se/`
    app_url_start = "http://" + host + "/"
} else {

    let m = host.match(/app\.(.*)\.zebrain\.se/)
    console.log("host", host)
    console.log("m", m)
    
    if (m === null) {
        // TODO: localhost testing
    
        system = "prod"
    } else {
        system = m[1]
    }

    api_endpoint = "https://" + host.replace('app', 'api') + "/api/v1/"
    home_page = `https://www.zebrain.se/`
    app_url_start = "http://" + host + "/"

}

// let host = window.location.host.split(':')[0];
// let api_endpoint = "http://" + host + ":8000/api/v1/"
let web_socket_endpoint = "wss://websocket-prod.whiteflower-6b55dc84.swedencentral.azurecontainerapps.io/wss:443"
// let home_page = "http://" + host + "/"
// let app_url_start = "http://" + host + ":8100/"
// let system = "develop"


// if (process.env.NODE_ENV === 'production') {
//     if (process.env.REACT_APP_SYSTEM === 'demo' || host === 'app-demo.zebrain.se') {
//         api_endpoint = 'https://api-demo.zebrain.se/api/v1/'
//         home_page = "https://demo.zebrain.se/"
//         app_url_start = "https://app-demo.zebrain.se/"
//         system = "demo"
//     } else if (process.env.REACT_APP_SYSTEM === 'stage') {
//         api_endpoint = 'https://api-stage.zebrain.se/api/v1/'
//         home_page = "https://stage.zebrain.se/"
//         app_url_start = "https://app-stage.zebrain.se/"
//         system = "stage"
//     } else {
//         api_endpoint = 'https://api2.zebrain.se/api/v1/'
//         home_page = "https://www.zebrain.se/"
//         app_url_start = "https://app2.zebrain.se/"
//         system = "prod"
//     }
// }

export interface AppConfig {
    API_ENDPOINT: string,
    WEB_SOCKET_ENDPOINT: string,
    VERSION: string,
    NAME: string,
    TOKEN_NAME: string,
    PERSON_ID: string,
    ENV: string,
    HOME_PAGE: string,
    APP_URL_START: string,
    SYSTEM: string,
}

export const CONFIG: AppConfig = {
    API_ENDPOINT: api_endpoint,
    WEB_SOCKET_ENDPOINT: web_socket_endpoint,
    VERSION: "2.0.62",
    NAME: "ZebrainApp",
    TOKEN_NAME: 'zebrain_token',
    PERSON_ID: 'zebrain_person_id',
    ENV: process.env.NODE_ENV,
    HOME_PAGE: home_page,
    APP_URL_START: app_url_start,
    SYSTEM: system
};
